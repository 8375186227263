
import { Component, Vue } from 'vue-property-decorator';
import { RouteConfigSingleView } from 'vue-router/types/router';
import { flatRouter, routes } from '@/router/routes';
import { BankCardVerificationService } from '@/services/bankCard';
import { FORM_ERRORS } from '@/components/registration/settings/rules';
import { DisbursementCard } from '@/api/models/disbursement';
import { ResponseList } from '@/api/client';

const flatRoutes = flatRouter(routes);

type TRoute = RouteConfigSingleView;

interface BankCardVerificationState {
  getVerificationLoading: () => boolean;
  getVerificationError: () => string;
}

@Component({
  name: 'BankCardVerificationHandler'
})
export default class BankCardVerificationHandler extends Vue implements BankCardVerificationState {
  private currentRoute: TRoute;
  private verificationService = BankCardVerificationService;

  public verificationError = '';
  public verificationLoading = false;

  get verificationState() {
    return this.verificationService.verification;
  }

  get verificationCard() {
    return this.verification?.receiptStamp;
  }

  get isCheckRequired() {
    return this.verificationService.hasTestAmountCheck;
  }

  private detectCurrentRoute() {
    const RouteName = this.$route.name;
    const found = flatRoutes.find(({ name }) => name === RouteName) as RouteConfigSingleView;
    this.currentRoute = found || this.$route;
  }

  get currentRoutePath() {
    return this.currentRoute.path.split('/:')[0] || '';
  }

  get verification() {
    return this.verificationState?.response ?? null;
  }

  async startVerification() {
    this.verificationError = '';
    this.verificationLoading = true;
    try {
      await this.verificationService.initVerification(this.currentRoute.path);
    } catch (data) {
      this.verificationError = FORM_ERRORS.FIELD_ERROR_VERIFICATION_CREATE;
    } finally {
      this.verificationLoading = false;
    }
  }

  async detectVerification() {
    this.verificationLoading = true;
    try {
      await this.verificationService.getVerificationOnRedirect(this.$route);
    } catch (data) {
      this.verificationError = FORM_ERRORS.FIELD_ERROR_VERIFICATION_RESTORE_FAILED;
    } finally {
      this.verificationLoading = false;
    }
  }

  async unlinkVerification() {
    this.verificationService.destroyVerification();
  }

  async handleCheck(testAmount: string) {
    this.verificationError = '';
    this.verificationLoading = true;
    try {
      await this.verificationService.verification?.verify({
        testAmount
      });
    } catch ({ data, data: { detail, status } }) {
      this.verificationError = `FIELD_ERROR_CONFIRM_AMOUNT_${status}`;
      await this.verificationService.reloadVerification();
    } finally {
      this.verificationLoading = false;
    }
  }

  mounted() {
    this.detectCurrentRoute();
    this.detectVerification();
  }

  getDisbursement() {
    return this.verificationService.disbursement;
  }

  getVerificationLoading() {
    return this.verificationLoading;
  }

  getVerificationError() {
    return this.verificationError;
  }

  findDisbursement(cardData: DisbursementCard | null, list: ResponseList<DisbursementCard>) {
    if (cardData) {
      const { data: subject } = cardData;

      return list.find(({ data: item }) => {
        const sameUser = item.userId === subject.userId;
        const sameLogin = item.userLogin === subject.userLogin;
        const sameMask = item.panMasked === subject.panMasked;

        return sameUser && sameLogin && sameMask;
      });
    }
  }
}
