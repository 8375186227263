
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'LmsModal'
})
export default class Modal extends Vue {
  $refs!: {
    modal: HTMLDivElement;
  };

  mounted() {
    this.$refs.modal.focus();
  }

  close($event?: MouseEvent) {
    this.$emit('close', $event);
  }
}
