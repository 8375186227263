
import { isMobileOnly } from 'mobile-device-detect';
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import Field from '@/components/registration/Field.vue';
import { Option, SelectSetting } from '@/components/registration/models/select';

const defaultSetting: SelectSetting = {
  placeholder: false,
  title: true,
  tooltip: false,
  options: () => [],
  disabled: false
};

@Component({
  name: 'LmsSelect'
})
export default class FieldSelect extends Mixins(Field) {
  @Prop({ default: () => defaultSetting })
  setting!: SelectSetting;

  isMobile = isMobileOnly;

  options: Option[] = [];

  @Watch('$i18n.locale', { immediate: true })
  async loadOptions() {
    this.options = await this.setting.options();
  }

  // We use only the initial value for inputs and emit the resulting value up,
  // this hack watcher is needed to programmatically change the value in the input
  @Watch('initial')
  updateInitial() {
    this.value = this.initial;
  }

  get placeholder() {
    return this.$t(`FORM.FIELDS.${this.name}.${this.setting.placeholder ? 'PLACEHOLDER' : 'TITLE'}`);
  }

  get tooltip() {
    return this.setting.tooltip ? this.$t(`FORM.FIELDS.${this.name}.TOOLTIP`) : '';
  }

  onFocus() {
    this.focused = true;
    this.invalidate();
  }

  onBlur() {
    this.focused = false;
  }

  @Emit('choose-option')
  handleChange() {
    return this.options.filter(option => option.value === this.value)[0];
  }
}
