
import { UploadSource } from '@/api/models/document';
import { Component, Mixins, Prop } from 'vue-property-decorator';
import Field from '@/components/registration/Field.vue';
import { FileMaxSize, validateFileSize } from '@/utils/file';
import { FORM_ERRORS } from '@/components/registration/settings/rules';

@Component({
  name: 'LmsMultiInput'
})
export default class MultiImageUpload extends Mixins(Field) {
  @Prop({ default: () => 1 })
  maxItems: number;
  @Prop({ required: true })
  placeholder: string;
  @Prop({ required: true })
  tooltip: string;

  focused = false;
  value: UploadSource[] = [];
  valid = true;

  private notifyError(message: string) {
    this.valid = false;
    this.error = message;
  }

  get limited() {
    return this.value.length >= this.maxItems;
  }

  onError() {
    this.notifyError(FORM_ERRORS.BANK_STATEMENT_UPLOAD_ERROR);
  }

  onFocus() {
    this.focused = true;
  }

  onBlur() {
    this.focused = false;
  }

  get isValid() {
    return this.valid;
  }

  limitReached(files: any) {
    this.valid = true;

    let uploadSources = (Array.from(files) as File[]).map(
      (file: File): UploadSource => {
        return {
          raw: file,
          name: file.name,
          percentage: 0,
          status: 'success',
          uid: 0,
          size: file.size
        };
      }
    );

    uploadSources = uploadSources.filter((file: UploadSource) => {
      if (!validateFileSize(file.raw, FileMaxSize.BANK_STATEMENT)) {
        this.notifyError(FORM_ERRORS.BANK_STATEMENT_FILE_SIZE);
        return false;
      }

      return true;
    });

    if (uploadSources.length > this.maxItems) {
      this.notifyError(FORM_ERRORS.BANK_STATEMENT_LIMIT_REACHED);
    }

    this.value = uploadSources;
    this.$emit('input', this.value);
  }

  validate(): void {
    if (this.value.length > this.maxItems) {
      this.notifyError(FORM_ERRORS.BANK_STATEMENT_LIMIT_REACHED);
    }
  }

  onRemove(file: UploadSource, fileList: UploadSource[]) {
    this.valid = true;
    this.error = '';

    this.value = fileList;
    this.$emit('input', this.value);
  }

  onChange(file: UploadSource, fileList: UploadSource[]) {
    this.valid = true;
    this.error = '';

    if (!validateFileSize(file.raw, FileMaxSize.BANK_STATEMENT)) {
      this.notifyError(FORM_ERRORS.BANK_STATEMENT_FILE_SIZE);
      fileList.pop();
      this.value = fileList;
    } else {
      this.value = fileList;
    }

    this.$emit('input', this.value);
  }
}
