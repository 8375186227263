
import { Vue, Component, Emit, Prop } from 'vue-property-decorator';
import LmsModal from '@/components/layout/modal.vue';
import { ScheduleGraphicPayment } from '@/components/schedule/ScheduleGraphic.types';

interface ScheduleGraphicComponent {
  payments: ScheduleGraphicPayment[];
  closeGraphic: () => void;
}

@Component({
  components: { LmsModal }
})
export default class ScheduleGraphic extends Vue implements ScheduleGraphicComponent {
  @Prop({ required: true })
  payments!: ScheduleGraphicPayment[];

  @Emit()
  closeGraphic() {
    return false;
  }
}
